// @mui material components
// import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Datetime from "react-datetime";
import moment from "moment";
// import getTokens from "api/getTokens";
import getAllClients from "api/clients/getAllClients";
import controlUrlAvailable from "api/clients/controlUrlAvailable";
import getAvailableServices from "api/servicepackages/getAvailableServices";
import getAllSectors from "api/ransomware/getAllSectors";
import getAllGangs from "api/ransomware/getAllGangs";
import addLog from "api/logs/addLog";
import editClient from "api/clients/editClient";
import refreshClientsDomains from "api/clients/updateClientsDomains";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Autocomplete from "layouts/dashboards/stealers/autocomplete";
import MDInput from "components/MDInput";
import { useMaterialUIController } from "context";
import { validateEmail, validateUrlOrEmail, validateCC } from "utilities/validateEmail";
import SweetAlert from "react-bootstrap-sweetalert";

// Material Dashboard 2 PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Pagination from "layouts/dashboards/stealers/pagination";
import AddServiceDetails from "layouts/backoffice/clientList/addServiceDetails";

// Icon import
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import MDTypography from "components/MDTypography";

import countryCode from "config/countryCode";
import deleteClient from "api/clients/deleteClient";

function ClientList() {
  // eslint-disable-next-line
  const [controller, dispatch] = useMaterialUIController();
  const { user } = controller;
  const [clients, setClients] = useState([]);
  const [originalClients, setOriginalClients] = useState([]);
  const [editElement, setEditElement] = useState(null);
  const [popup, setPopup] = useState(false);
  const [popupUrlAlready, setPopupUrlAlready] = useState(false);
  const [workerStarted, setWorkerStarted] = useState(false);
  const [popupSuccess, setPopupSuccess] = useState(false);
  // eslint-disable-next-line
  const [pages, setPages] = useState(0);
  // eslint-disable-next-line
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(50);
  const [name, setName] = useState("");
  const [vatId, setVatId] = useState("");
  const [expiration, setExpiration] = useState(null);
  const [start, setStart] = useState(null);
  const [passwordurl, setPasswordurl] = useState("");
  const [passwordurlError, setPasswordurlError] = useState(false);
  const [username, setUsername] = useState("");
  const [usernameError, setUsernameError] = useState(false);
  const [combo, setCombo] = useState("");
  const [comboError, setComboError] = useState(false);
  const [urlscanSearchId, setUrlscanSearchId] = useState("");
  const [urlscanSearchIdError, setUrlscanSearchIdError] = useState(false);
  const [creditcard, setCreditcard] = useState("");
  const [creditcardError, setCreditcardError] = useState(false);
  const [pwUrl, setPwUrl] = useState("");
  const [pwUrlApp, setPwUrlApp] = useState("");
  const [pwUrlDomain, setPwUrlDomain] = useState("");
  const [email, setEmail] = useState([]);
  const [errorEmail, setErrorEmail] = useState(false);
  const [emailInput, setEmailInput] = useState("");
  const [ip, setIp] = useState([]);
  const [errorIp, setErrorIp] = useState(false);
  const [ipInput, setIpInput] = useState("");
  // const [prefix, setPrefix] = useState("");
  // const [phone, setPhone] = useState("");
  const [webhooks, setWebhooks] = useState([]);
  const [webhookUrl, setWebhookUrl] = useState("");
  const [webhookToken, setWebhookToken] = useState("");
  const [webhookAuthHeader, setWebhookAuthHeader] = useState("");
  const [webhookContentType, setWebhookContentType] = useState("");
  const [webhookError, setWebhookError] = useState(false);
  const [cookiename, setCookiename] = useState("");
  const [wildcard, setWildcard] = useState(false);
  const [cookieurl, setCookieurl] = useState("");
  const [cookieClientName, setCookieClientName] = useState("");
  const [cookieClientUrl, setCookieClientUrl] = useState("");
  const [cookieurlError, setCookieurlError] = useState(false);
  const [cookieClientUser, setCookieClientUser] = useState("");
  const [status, setStatus] = useState("active");
  const [service, setService] = useState([]);
  const [serviceName, setServiceName] = useState("premium");
  const [servicesAvailable, setServicesAvailable] = useState([]);
  const [serviceDetail, setServiceDetail] = useState([]);
  const [hasChangedDomains, setHasChangedDomains] = useState(false);
  // const [hasInsurance, setHasInsurance] = useState(false);
  const [cookiesClient, setCookiesClient] = useState([]);
  const [passwords, setPasswords] = useState([]);
  const [usernames, setUsernames] = useState([]);
  const [combos, setCombos] = useState([]);
  const [urlscanSearchIds, setUrlscanSearchIds] = useState([]);
  const [creditcards, setCreditcards] = useState([]);
  const [cookies, setCookies] = useState([]);
  const [pwUrls, setPwUrls] = useState([]);
  const [pwUrlsApps, setPwUrlsApps] = useState([]);
  const [ransomwares, setRansomwares] = useState([]);
  const [ransomwareVictim, setRansomwareVictim] = useState("");
  const [ransomwareCountry, setRansomwareCountry] = useState("");
  const [ransomwareGang, setRansomwareGang] = useState("");
  const [ransomwareSector, setRansomwareSector] = useState("");
  const [ransomwareDomain, setRansomwareDomain] = useState("");
  const [ransomwareCompanySize, setRansomwareCompanySize] = useState("-");
  const [allSectors, setAllSectors] = useState([]);
  const [allGangs, setAllGangs] = useState([]);
  // eslint-disable-next-line
  const [wildcardPwApp, setWildcardPwApp] = useState(false);
  const [orderBy, setOrderBy] = useState("name");
  const [deleteClientPopup, setDeleteClientPopup] = useState(false);
  const [deleteClientPopupSuccess, setDeleteClientPopupSuccess] = useState(false);
  const [lookupAfter, setLookupAfter] = useState("");
  const { t } = useTranslation();

  function editService(type) {
    setServiceName(type);
    // switch (type) {
    //   case "premium":
    //     setService(["stealer", "ransomware", "combo"]);
    //     break;
    //   case "stealer":
    //     setService(["stealer", "ransomware"]);
    //     break;
    //   case "combo":
    //     setService(["combo"]);
    //     break;
    //   case "combo_potential":
    //     setService(["stealer", "ransomware", "combo"]);
    //     break;
    //   default:
    //     setService(["stealer", "ransomware", "combo"]);
    // }
  }

  function refreshClients() {
    getAllClients(page, limit)
      .then((res) => {
        if (res.results && res.results.length > 0) {
          if (res.totalPages) {
            setPages(res.totalPages);
          }
          setOriginalClients(res.results);
          setClients(res.results.sort((a, b) => a.name.localeCompare(b.name)));
        }
      })
      // eslint-disable-next-line
      .catch((err) => console.log("error", err));
  }

  useEffect(() => {
    refreshClients();
    getAllSectors()
      .then((res) => {
        setAllSectors(res);
      })
      // eslint-disable-next-line
      .catch((err) => console.log("error getting sectors", err));
    getAllGangs()
      .then((res) => res.filter((el) => el))
      .then((gangs) => {
        setAllGangs(gangs);
      })
      // eslint-disable-next-line
      .catch((err) => console.log("error getting gangs", err));
    getAvailableServices()
      .then((resAvailableServices) => {
        setServicesAvailable(resAvailableServices);
      })
      // eslint-disable-next-line
      .catch((err) => console.log("error getting available services", err));
  }, []);

  useEffect(() => {
    refreshClients();
  }, [page, limit]);

  function removePasswords(index) {
    const newArray = [].concat(passwords);
    newArray.splice(index, 1);
    setPasswords(newArray);
  }

  function removeUsernames(index) {
    const newArray = [].concat(usernames);
    newArray.splice(index, 1);
    setUsernames(newArray);
  }

  function removeCombos(index) {
    const newArray = [].concat(combos);
    newArray.splice(index, 1);
    setCombos(newArray);
  }

  function removeUrlscanSearchId(index) {
    const newArray = [].concat(urlscanSearchIds);
    newArray.splice(index, 1);
    setUrlscanSearchIds(newArray);
  }

  function removeCreditcards(index) {
    const newArray = [].concat(creditcards);
    newArray.splice(index, 1);
    setCreditcards(newArray);
  }

  function controlAddition(content, self) {
    return controlUrlAvailable(content, self)
      .then((res) => res)
      .catch((err) => {
        // eslint-disable-next-line
        console.log("error while checking availability", err);
      });
  }

  function addPasswords(elem) {
    const newArray = [].concat(passwords);
    newArray.push(elem);
    setPasswords(newArray);
  }

  function addUsernames(elem) {
    const newArray = [].concat(usernames);
    newArray.push(elem);
    setUsernames(newArray);
  }

  function addCombos(elem) {
    const newArray = [].concat(combos);
    newArray.push(elem);
    setCombos(newArray);
  }

  function addUrlscanSearchId(elem) {
    const newArray = [].concat(urlscanSearchIds);
    newArray.push(elem);
    setUrlscanSearchIds(newArray);
  }

  function addCreditcards(elem) {
    const newArray = [].concat(creditcards);
    newArray.push(elem);
    setCreditcards(newArray);
  }

  function removeCookies(index) {
    const newArray = [].concat(cookies);
    newArray.splice(index, 1);
    setCookies(newArray);
  }

  function removeCookiesClient(index) {
    const newArray = [].concat(cookiesClient);
    newArray.splice(index, 1);
    setCookiesClient(newArray);
  }

  function addCookies(elem) {
    const newArray = [].concat(cookies);
    newArray.push(elem);
    setCookies(newArray);
  }

  function addCookiesClient(elem) {
    const newArray = [].concat(cookiesClient);
    newArray.push(elem);
    setCookiesClient(newArray);
  }

  function addPwUrls(elem) {
    const newArray = [].concat(pwUrls);
    newArray.push(elem);
    setPwUrls(newArray);
  }

  function removePwUrls(index) {
    const newArray = [].concat(pwUrls);
    newArray.splice(index, 1);
    setPwUrls(newArray);
  }

  function addPwUrlsApps(elem) {
    const newArray = [].concat(pwUrlsApps);
    newArray.push(elem);
    setPwUrlsApps(newArray);
  }

  function removePwUrlsApps(index) {
    const newArray = [].concat(pwUrlsApps);
    newArray.splice(index, 1);
    setPwUrlsApps(newArray);
  }

  function addEmail(elem) {
    const newArray = [].concat(email);
    if (!newArray.includes(elem)) {
      newArray.push(elem);
      setEmail(newArray);
    }
  }

  function removeEmail(index) {
    const newArray = [].concat(email);
    newArray.splice(index, 1);
    setEmail(newArray);
  }

  function addIp(elem) {
    const newArray = [].concat(ip);
    if (!newArray.includes(elem)) {
      newArray.push(elem);
      setIp(newArray);
    }
  }

  function removeIp(index) {
    const newArray = [].concat(ip);
    newArray.splice(index, 1);
    setIp(newArray);
  }

  function addRansomware() {
    const newArray = [].concat(ransomwares);
    const elem = {
      victim: ransomwareVictim || "",
      country: ransomwareCountry || "",
      gang: ransomwareGang || "",
      domain: ransomwareDomain || "",
      sector: ransomwareSector || "",
      company_size: ransomwareCompanySize === "-" ? "" : ransomwareCompanySize || "",
    };
    newArray.push(elem);
    setRansomwares(newArray);
    setRansomwareVictim("");
    setRansomwareCountry("");
    setRansomwareGang("");
    setRansomwareDomain("");
    setRansomwareSector("");
    setRansomwareCompanySize("-");
  }

  function removeRansomware(index) {
    const newArray = [].concat(ransomwares);
    newArray.splice(index, 1);
    setRansomwares(newArray);
  }

  const selectSector = (element) => {
    setRansomwareSector(element);
  };

  const selectGang = (element) => {
    setRansomwareGang(element);
  };

  const selectAutocomplete = (element) => {
    setRansomwareCountry(element);
  };

  function addWebhook(elem) {
    const newArray = [].concat(webhooks);
    if (!newArray.includes(elem)) {
      newArray.push(elem);
      setWebhooks(newArray);
    }
  }

  function removeWebhook(index) {
    const newArray = [].concat(webhooks);
    newArray.splice(index, 1);
    setWebhooks(newArray);
  }

  function getInput(type, editFunction, defaultValue) {
    return (
      <MDInput
        type="input"
        label={type}
        fullWidth
        value={defaultValue}
        onChange={(e) => {
          editFunction(e.target.value);
        }}
        style={{ marginBottom: "30px" }}
      />
    );
  }

  function getInputCookie(clientId) {
    return (
      <>
        <MDTypography fontSize="small" mb={1} textAlign="left">
          {t("admin.cookies_simple_explanation")}
        </MDTypography>
        <div style={{ position: "relative", textAlign: "left" }}>
          <MDInput
            type="input"
            label="Cookie url"
            fullWidth
            value={cookieurl}
            className={cookieurlError ? "input-error" : ""}
            onKeyDown={(e) => {
              if (e.key === "Enter" && cookieurl && cookiename && validateUrlOrEmail(cookieurl)) {
                const elem = { url: cookieurl, name: cookiename };
                controlAddition(
                  {
                    type: "cookies",
                    content: elem,
                  },
                  clientId
                )
                  .then((res) => {
                    if (res) {
                      addCookies({ ...elem, wildcard });
                      setCookieurl("");
                      setWildcard(false);
                      setCookiename("");
                      setHasChangedDomains(true);
                    } else {
                      setPopupUrlAlready(true);
                    }
                  })
                  .catch(() => {
                    setPopupUrlAlready(true);
                  });
              }
            }}
            onChange={(e) => {
              if (!validateUrlOrEmail(e.target.value)) {
                setCookieurlError(true);
              } else {
                setCookieurlError(false);
              }
              setCookieurl(e.target.value);
            }}
            style={{ width: "50%" }}
          />
          <MDInput
            type="input"
            label="Cookie name"
            fullWidth
            value={cookiename}
            onKeyDown={(e) => {
              if (e.key === "Enter" && cookieurl && cookiename && validateUrlOrEmail(cookieurl)) {
                const elem = { url: cookieurl, name: cookiename };
                controlAddition(
                  {
                    type: "cookies",
                    content: elem,
                  },
                  clientId
                )
                  .then((res) => {
                    if (res) {
                      addCookies({ ...elem, wildcard });
                      setCookieurl("");
                      setWildcard(false);
                      setCookiename("");
                      setHasChangedDomains(true);
                    } else {
                      setPopupUrlAlready(true);
                    }
                  })
                  .catch(() => {
                    setPopupUrlAlready(true);
                  });
              }
            }}
            onChange={(e) => {
              setCookiename(e.target.value);
            }}
            style={{ width: "50%" }}
          />
          <input
            type="checkbox"
            style={{ marginBottom: "30px" }}
            value={wildcard}
            onChange={() => {
              setWildcard(!wildcard);
            }}
          />{" "}
          <span style={{ fontSize: "14px" }}>Cookie wildcard</span>
          <div className="clientInputArrayButton">
            <IconButton
              onClick={() => {
                if (cookiename && cookieurl && validateUrlOrEmail(cookieurl)) {
                  const elem = { url: cookieurl, name: cookiename };
                  controlAddition(
                    {
                      type: "cookies",
                      content: elem,
                    },
                    clientId
                  )
                    .then((res) => {
                      if (res) {
                        addCookies({ ...elem, wildcard });
                        setCookieurl("");
                        setWildcard(false);
                        setCookiename("");
                        setHasChangedDomains(true);
                      } else {
                        setPopupUrlAlready(true);
                      }
                    })
                    .catch(() => {
                      setPopupUrlAlready(true);
                    });
                }
              }}
              size="small"
              disableRipple
            >
              <Icon fontSize="medium">add</Icon>
            </IconButton>
          </div>
        </div>
      </>
    );
  }

  function getInputCookieClient() {
    return (
      <>
        <MDTypography fontSize="small" mb={1} textAlign="left">
          {t("admin.cookies_client_explanation")}
        </MDTypography>
        <div style={{ position: "relative", textAlign: "left" }}>
          <MDInput
            type="input"
            label={t("admin.cookies_client_url")}
            fullWidth
            value={cookieClientUrl}
            onKeyDown={(e) => {
              if (e.key === "Enter" && cookieClientUrl && cookieClientName && cookieClientUser) {
                const elem = { url: cookieClientUrl, name: cookieClientName };
                controlAddition({
                  type: "cookies",
                  content: elem,
                })
                  .then((res) => {
                    if (res) {
                      addCookiesClient({ ...elem, user: cookieClientUser });
                      setCookieClientUrl("");
                      setCookieClientName("");
                      setCookieClientUser("");
                    } else {
                      setPopupUrlAlready(true);
                    }
                  })
                  .catch(() => {
                    setPopupUrlAlready(true);
                  });
              }
            }}
            onChange={(e) => {
              setCookieClientUrl(e.target.value);
            }}
            style={{ width: "50%" }}
          />
          <MDInput
            type="input"
            label={t("admin.cookies_client_name")}
            fullWidth
            value={cookieClientName}
            onKeyDown={(e) => {
              if (e.key === "Enter" && cookieClientUrl && cookieClientName && cookieClientUser) {
                const elem = { url: cookieClientUrl, name: cookieClientName };
                controlAddition({
                  type: "cookies",
                  content: elem,
                })
                  .then((res) => {
                    if (res) {
                      addCookiesClient({ ...elem, user: cookieClientUser });
                      setCookieClientUrl("");
                      setCookieClientName("");
                      setCookieClientUser("");
                    } else {
                      setPopupUrlAlready(true);
                    }
                  })
                  .catch(() => {
                    setPopupUrlAlready(true);
                  });
              }
            }}
            onChange={(e) => {
              setCookieClientName(e.target.value);
            }}
            style={{ width: "50%" }}
          />
          <MDInput
            type="input"
            label={t("admin.cookies_client_user")}
            fullWidth
            value={cookieClientUser}
            onKeyDown={(e) => {
              if (e.key === "Enter" && cookieClientUrl && cookieClientName && cookieClientUser) {
                const elem = { url: cookieClientUrl, name: cookieClientName };
                controlAddition({
                  type: "cookies",
                  content: elem,
                })
                  .then((res) => {
                    if (res) {
                      addCookiesClient({ ...elem, user: cookieClientUser });
                      setCookieClientUrl("");
                      setCookieClientName("");
                      setCookieClientUser("");
                    } else {
                      setPopupUrlAlready(true);
                    }
                  })
                  .catch(() => {
                    setPopupUrlAlready(true);
                  });
              }
            }}
            onChange={(e) => {
              setCookieClientUser(e.target.value);
            }}
            style={{ width: "50%", marginBottom: "30px", marginTop: "5px" }}
          />
          <div className="clientInputArrayButton">
            <IconButton
              onClick={() => {
                if (cookieClientUrl && cookieClientName && cookieClientUser) {
                  const elem = { url: cookieClientUrl, name: cookieClientName };
                  controlAddition({
                    type: "cookies",
                    content: elem,
                  })
                    .then((res) => {
                      if (res) {
                        addCookiesClient({ ...elem, user: cookieClientUser });
                        setCookieClientUrl("");
                        setCookieClientName("");
                        setCookieClientUser("");
                      } else {
                        setPopupUrlAlready(true);
                      }
                    })
                    .catch(() => {
                      setPopupUrlAlready(true);
                    });
                }
              }}
              size="small"
              disableRipple
            >
              <Icon fontSize="medium">add</Icon>
            </IconButton>
          </div>
        </div>
      </>
    );
  }

  function getInputPwUrl(clientId) {
    return (
      <>
        <MDTypography fontSize="small" mb={1} textAlign="left">
          {t("admin.passwords_url_explanation")}
        </MDTypography>
        <div style={{ position: "relative" }}>
          <MDInput
            type="input"
            label="Password url"
            fullWidth
            value={pwUrl}
            onKeyDown={(e) => {
              if (e.key === "Enter" && pwUrl) {
                const elem = { url: pwUrl, domain: pwUrlDomain || "" };
                controlAddition(
                  {
                    type: "passwordsUrl",
                    content: elem,
                  },
                  clientId
                )
                  .then((res) => {
                    if (res) {
                      addPwUrls(elem);
                      setPwUrl("");
                      setPwUrlDomain("");
                      setHasChangedDomains(true);
                    } else {
                      setPopupUrlAlready(true);
                    }
                  })
                  .catch(() => {
                    setPopupUrlAlready(true);
                  });
              }
            }}
            onChange={(e) => {
              setPwUrl(e.target.value);
            }}
            style={{ marginBottom: "30px", width: "50%" }}
          />
          <MDInput
            type="input"
            label="Password domain"
            fullWidth
            value={pwUrlDomain}
            onKeyDown={(e) => {
              if (e.key === "Enter" && pwUrl) {
                const elem = { url: pwUrl, domain: pwUrlDomain || "" };
                controlAddition(
                  {
                    type: "passwordsUrl",
                    content: elem,
                  },
                  clientId
                )
                  .then((res) => {
                    if (res) {
                      addPwUrls(elem);
                      setPwUrl("");
                      setPwUrlDomain("");
                      setHasChangedDomains(true);
                    } else {
                      setPopupUrlAlready(true);
                    }
                  })
                  .catch(() => {
                    setPopupUrlAlready(true);
                  });
              }
            }}
            onChange={(e) => {
              setPwUrlDomain(e.target.value);
            }}
            style={{ marginBottom: "30px", width: "50%" }}
          />
          <div className="clientInputArrayButton">
            <IconButton
              onClick={() => {
                if (pwUrl) {
                  const elem = { url: pwUrl, domain: pwUrlDomain || "" };
                  controlAddition(
                    {
                      type: "passwordsUrl",
                      content: elem,
                    },
                    clientId
                  )
                    .then((res) => {
                      if (res) {
                        addPwUrls(elem);
                        setPwUrl("");
                        setPwUrlDomain("");
                        setHasChangedDomains(true);
                      } else {
                        setPopupUrlAlready(true);
                      }
                    })
                    .catch(() => {
                      setPopupUrlAlready(true);
                    });
                }
              }}
              size="small"
              disableRipple
            >
              <Icon fontSize="medium">add</Icon>
            </IconButton>
          </div>
        </div>
      </>
    );
  }

  function getInputPwUrlApp() {
    return (
      <>
        <MDTypography fontSize="small" mb={1} textAlign="left">
          {t("admin.passwords_app_domains_explanation")}
        </MDTypography>
        <div style={{ position: "relative", display: "flex" }}>
          <MDInput
            type="input"
            label="Password url app"
            fullWidth
            value={pwUrlApp}
            onKeyDown={(e) => {
              if (e.key === "Enter" && pwUrlApp) {
                const elem = { domain: pwUrlApp || "", wildcard: wildcardPwApp };
                addPwUrlsApps(elem);
                setPwUrlApp("");
              }
            }}
            onChange={(e) => {
              setPwUrlApp(e.target.value);
            }}
            style={{
              marginBottom: "30px",
              width: "50%",
            }}
          />
          {/* <div
            style={{
              marginBottom: "30px",
              marginLeft: "10px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <input
              type="checkbox"
              value={wildcardPwApp}
              onChange={() => {
                setWildcardPwApp(!wildcardPwApp);
              }}
            />{" "}
            <span style={{ fontSize: "14px", marginLeft: "10px" }}>Cookie wildcard</span>
          </div> */}
          <div className="clientInputArrayButton">
            <IconButton
              onClick={() => {
                if (pwUrlApp) {
                  const elem = { domain: pwUrlApp || "", wildcard: wildcardPwApp };
                  addPwUrlsApps(elem);
                  setPwUrlApp("");
                }
              }}
              size="small"
              disableRipple
            >
              <Icon fontSize="medium">add</Icon>
            </IconButton>
          </div>
        </div>
      </>
    );
  }

  function removeServiceDetail(index, serviceCode) {
    const newServiceDetail = [].concat(serviceDetail);
    newServiceDetail.splice(index, 1);
    setServiceDetail(newServiceDetail);
    let newService = service;
    if (newService.includes(serviceCode)) {
      newService = newService.filter((el) => el !== serviceCode);
      setService(newService);
    }
  }

  function addServiceDetail(newElem) {
    const newServiceDetail = [].concat(serviceDetail);
    const indexOfNewServiceDetail = newServiceDetail.findIndex((el) => el.code === newElem.code);
    if (indexOfNewServiceDetail >= 0) {
      newServiceDetail[indexOfNewServiceDetail].expiresOn = newElem.expiresOn;
      setServiceDetail(newServiceDetail);
    } else {
      newServiceDetail.push(newElem);
      setServiceDetail(newServiceDetail);
    }
    const newService = [].concat(service);
    const indexOfNewService = newService.indexOf(newElem.code);
    if (indexOfNewService < 0) {
      newService.push(newElem.code);
      setService(newService);
    }
  }

  function getServices() {
    if (!serviceDetail || serviceDetail.length === 0) {
      return <div>{t("admin.no_services_yet")}</div>;
    }
    return (
      <div className="clientsViewArrayContainer">
        {serviceDetail.map((el, index) => (
          <div
            key={`service_detail_${el.code}_${index.toString()}`}
            className="clientsViewArrayElement"
          >
            {el.code} - {moment(el.expiresOn).format("DD/MM/YYYY")}
            <IconButton
              onClick={() => {
                removeServiceDetail(index, el.code);
              }}
              size="small"
              disableRipple
            >
              <Icon fontSize="medium">close</Icon>
            </IconButton>
          </div>
        ))}
      </div>
    );
  }

  function getInputArray(
    type,
    editFunction,
    addFunction,
    value,
    labelToCheck,
    clientId,
    labelExplanation,
    errorValue = false,
    setErrorValue = () => {},
    controlFunction = validateUrlOrEmail
  ) {
    return (
      <>
        <MDTypography fontSize="small" mb={1} textAlign="left">
          {labelExplanation}
        </MDTypography>
        <div style={{ position: "relative" }}>
          <MDInput
            type="input"
            label={type}
            fullWidth
            value={value}
            className={errorValue ? "input-error" : ""}
            onKeyDown={(e) => {
              if (e.key === "Enter" && value && controlFunction(value)) {
                setErrorValue(false);
                controlAddition(
                  {
                    type: labelToCheck,
                    content: e.target.value,
                  },
                  clientId
                )
                  .then((res) => {
                    if (res) {
                      addFunction(e.target.value);
                      editFunction("");
                      setHasChangedDomains(true);
                    } else {
                      setPopupUrlAlready(true);
                    }
                  })
                  .catch(() => {
                    setPopupUrlAlready(true);
                  });
              }
            }}
            onChange={(e) => {
              if (!controlFunction(e.target.value)) {
                setErrorValue(true);
              } else {
                setErrorValue(false);
              }
              editFunction(e.target.value);
            }}
            style={{ marginBottom: "30px" }}
          />
          <div className="clientInputArrayButton">
            <IconButton
              onClick={() => {
                if (value && controlFunction(value)) {
                  setErrorValue(false);
                  controlAddition(
                    {
                      type: labelToCheck,
                      content: value,
                    },
                    clientId
                  )
                    .then((res) => {
                      if (res) {
                        addFunction(value);
                        editFunction("");
                        setHasChangedDomains(true);
                      } else {
                        setPopupUrlAlready(true);
                      }
                    })
                    .catch(() => {
                      setPopupUrlAlready(true);
                    });
                }
              }}
              size="small"
              disableRipple
            >
              <Icon fontSize="medium">add</Icon>
            </IconButton>
          </div>
        </div>
      </>
    );
  }

  function getInputArrayEmail(
    type,
    editFunction,
    addFunction,
    value,
    labelToCheck,
    labelExplanation
  ) {
    return (
      <>
        <MDTypography fontSize="small" mb={1} textAlign="left">
          {labelExplanation}
        </MDTypography>
        <div style={{ position: "relative" }}>
          <MDInput
            type="input"
            label={type}
            fullWidth
            value={value}
            onKeyDown={async (e) => {
              if (e.key === "Enter" && value) {
                if (validateEmail(e.target.value)) {
                  setErrorEmail(false);
                  controlAddition({
                    type: labelToCheck,
                    content: e.target.value,
                  })
                    .then((res) => {
                      if (res) {
                        addFunction(e.target.value);
                        editFunction("");
                      } else {
                        setPopupUrlAlready(true);
                      }
                    })
                    .catch(() => {
                      setPopupUrlAlready(true);
                    });
                } else {
                  setErrorEmail(true);
                }
              }
            }}
            onChange={(e) => {
              editFunction(e.target.value);
            }}
            style={{ marginBottom: "30px" }}
          />
          <div className="clientInputArrayButton">
            <IconButton
              onClick={() => {
                if (value && validateEmail(value)) {
                  setErrorEmail(false);
                  controlAddition({
                    type: labelToCheck,
                    content: value,
                  })
                    .then((res) => {
                      if (res) {
                        addFunction(value);
                        editFunction("");
                      } else {
                        setPopupUrlAlready(true);
                      }
                    })
                    .catch(() => {
                      setPopupUrlAlready(true);
                    });
                } else {
                  setErrorEmail(true);
                }
              }}
              size="small"
              disableRipple
            >
              <Icon fontSize="medium">add</Icon>
            </IconButton>
          </div>
        </div>
        {errorEmail ? <div className="email_error">{t("admin.error_email")}</div> : null}
      </>
    );
  }

  function getInputRansomwares() {
    return (
      <>
        <MDTypography fontSize="small" mb={1} textAlign="left">
          {t("admin.ransomware_filters_explenation")}
        </MDTypography>
        <div style={{ position: "relative" }}>
          <MDInput
            type="input"
            label={t("admin.ransomware_victim")}
            fullWidth
            value={ransomwareVictim}
            onKeyDown={async (e) => {
              if (
                e.key === "Enter" &&
                (ransomwareVictim ||
                  ransomwareGang ||
                  ransomwareCountry ||
                  ransomwareSector ||
                  ransomwareDomain)
              ) {
                addRansomware();
              }
            }}
            onChange={(e) => {
              setRansomwareVictim(e.target.value);
            }}
            style={{ marginBottom: "30px" }}
          />
          <div style={{ marginBottom: "30px" }}>
            <Autocomplete
              label={t("admin.ransomware_gang")}
              onSelect={selectGang}
              list={allGangs}
            />
          </div>
          <div style={{ marginBottom: "30px" }}>
            <Autocomplete
              label={t("admin.ransomware_country")}
              onSelect={selectAutocomplete}
              list={Object.keys(countryCode).map((el) => ({
                label: t(`countryCode.${el}`),
                value: el,
              }))}
            />
          </div>
          <div style={{ marginBottom: "30px" }}>
            <Autocomplete
              label={t("admin.ransomware_sector")}
              onSelect={selectSector}
              list={allSectors}
            />
          </div>
          <MDInput
            type="input"
            label={t("admin.ransomware_domain")}
            fullWidth
            value={ransomwareDomain}
            onKeyDown={async (e) => {
              if (
                e.key === "Enter" &&
                (ransomwareVictim ||
                  ransomwareGang ||
                  ransomwareCountry ||
                  ransomwareSector ||
                  ransomwareDomain)
              ) {
                addRansomware();
              }
            }}
            onChange={(e) => {
              setRansomwareDomain(e.target.value);
            }}
            style={{ marginBottom: "30px" }}
          />
          <div style={{ marginBottom: "30px" }}>
            <MDTypography fontSize="small" mb={1} textAlign="left">
              {t("admin.company_size")}
            </MDTypography>
            <select
              onChange={(e) => {
                setRansomwareCompanySize(e.target.value);
              }}
              defaultValue={ransomwareCompanySize}
              className="selectDefault"
              style={{
                width: "100%",
                padding: "0.75rem",
                borderRadius: "0.375rem",
                borderColor: "#d2d6da",
                marginBottom: "30px",
              }}
            >
              <option value="-">-</option>
              <option value="L">{t("companySize.L")}</option>
              <option value="M">{t("companySize.M")}</option>
              <option value="U">{t("companySize.U")}</option>
              <option value="S">{t("companySize.S")}</option>
              <option value="P">{t("companySize.P")}</option>
            </select>
          </div>
          <div className="clientInputArrayButton">
            <IconButton
              onClick={() => {
                if (
                  ransomwareVictim ||
                  ransomwareGang ||
                  ransomwareCountry ||
                  ransomwareSector ||
                  ransomwareDomain
                ) {
                  addRansomware();
                }
              }}
              size="small"
              disableRipple
            >
              <Icon fontSize="medium">add</Icon>
            </IconButton>
          </div>
        </div>
      </>
    );
  }

  function viewArrayRansomwares() {
    return ransomwares.map((el, index) => (
      <div key={`ransomware-el-${index.toString()}`} className="clientsViewArrayElement">
        {el.victim || "No Victim"} - {el.gang || "No Gang"} - {el.country || "No Country"} -{" "}
        {el.sector || "No Sector"} - {el.domain || "No Domain"} -{" "}
        {el.company_size || "No Company size"}
        <IconButton
          onClick={() => {
            removeRansomware(index);
          }}
          size="small"
          disableRipple
        >
          <Icon fontSize="medium">close</Icon>
        </IconButton>
      </div>
    ));
  }

  function getInputArrayWebhook() {
    return (
      <>
        <MDTypography fontSize="small" mb={1} textAlign="left">
          {t("admin.wehbooks")}
        </MDTypography>
        <div style={{ position: "relative" }}>
          <MDInput
            type="input"
            label={t("admin.webhook_url")}
            fullWidth
            value={webhookUrl}
            className={webhookError ? "input-error" : ""}
            onKeyDown={async (e) => {
              if (e.key === "Enter" && webhookUrl && validateUrlOrEmail(webhookUrl)) {
                const newWebhook = getNewWebhook();
                addWebhook(newWebhook);
                clearWebhookData();
                setWebhookError(false);
              }
            }}
            onChange={(e) => {
              if (!validateUrlOrEmail(e.target.value)) {
                setWebhookError(true);
              } else {
                setWebhookError(false);
              }
              setWebhookUrl(e.target.value);
            }}
            style={{ marginBottom: "15px" }}
          />
          <MDInput
            type="input"
            label={t("admin.webhook_auth")}
            fullWidth
            value={webhookToken}
            onKeyDown={async (e) => {
              if (e.key === "Enter" && webhookUrl && validateUrlOrEmail(webhookUrl)) {
                const newWebhook = getNewWebhook();
                addWebhook(newWebhook);
                clearWebhookData();
              }
            }}
            onChange={(e) => {
              setWebhookToken(e.target.value);
            }}
            style={{ marginBottom: "15px" }}
          />
          <MDInput
            type="input"
            label={t("admin.webhook_auth_header")}
            fullWidth
            value={webhookAuthHeader}
            onKeyDown={async (e) => {
              if (e.key === "Enter" && webhookUrl && validateUrlOrEmail(webhookUrl)) {
                const newWebhook = getNewWebhook();
                addWebhook(newWebhook);
                clearWebhookData();
              }
            }}
            onChange={(e) => {
              setWebhookAuthHeader(e.target.value);
            }}
            style={{ marginBottom: "15px" }}
          />
          <MDInput
            type="input"
            label={t("admin.webhook_content_type")}
            fullWidth
            value={webhookContentType}
            onKeyDown={async (e) => {
              if (e.key === "Enter" && webhookUrl && validateUrlOrEmail(webhookUrl)) {
                const newWebhook = getNewWebhook();
                addWebhook(newWebhook);
                clearWebhookData();
              }
            }}
            onChange={(e) => {
              setWebhookContentType(e.target.value);
            }}
            style={{ marginBottom: "30px" }}
          />
          <div className="clientInputArrayButton">
            <IconButton
              onClick={() => {
                if (webhookUrl && validateUrlOrEmail(webhookUrl)) {
                  const newWebhook = getNewWebhook();
                  addWebhook(newWebhook);
                  clearWebhookData();
                }
              }}
              size="small"
              disableRipple
            >
              <Icon fontSize="medium">add</Icon>
            </IconButton>
          </div>
        </div>
      </>
    );
  }

  function clearWebhookData() {
    setWebhookUrl("");
    setWebhookToken("");
    setWebhookAuthHeader("");
    setWebhookContentType("");
  }

  function getNewWebhook() {
    return {
      url: webhookUrl || "",
      contentType: webhookContentType || "",
      auth: webhookToken || "",
      authHeader: webhookAuthHeader || "",
    };
  }

  function getSelect(options, editFunction, defaultValue) {
    return (
      <select
        onChange={(e) => {
          editFunction(e.target.value);
        }}
        defaultValue={defaultValue}
        className="selectDefault"
        style={{
          width: "100%",
          padding: "0.75rem",
          borderRadius: "0.375rem",
          borderColor: "#d2d6da",
          marginBottom: "30px",
        }}
      >
        {options.map((el, index) => (
          <option value={el} key={`option-role-${index.toString()}`}>
            {el}
          </option>
        ))}
      </select>
    );
  }

  function getCalendar(value, changeFunction, label) {
    return (
      <div>
        {value ? (
          <MDTypography fontSize="small" mb={1} textAlign="left">
            {label}
          </MDTypography>
        ) : (
          ""
        )}
        <div style={{ position: "relative", marginRight: "10px", marginBottom: "30px" }}>
          <Datetime
            inputProps={{
              placeholder: label,
              value: value ? moment(value).format("DD/MM/YYYY") : "",
            }}
            closeOnSelect
            timeFormat={false}
            value={value ? moment(value).format("DD/MM/YYYY") : ""}
            onChange={(e) => {
              changeFunction(moment(e).format("L"));
            }}
          />
          {value ? (
            <IconButton
              onClick={() => {
                changeFunction("");
              }}
              style={{ position: "absolute", right: "0", top: "6px" }}
              size="small"
              disableRipple
            >
              <Icon fontSize="medium">close</Icon>
            </IconButton>
          ) : null}
        </div>
      </div>
    );
  }

  function viewArrayElements(elems, removeFunction, type) {
    return elems.map((el, index) => (
      <div key={`${type}-el-${index.toString()}`} className="clientsViewArrayElement">
        {el}
        <IconButton
          onClick={() => {
            removeFunction(index);
          }}
          size="small"
          disableRipple
        >
          <Icon fontSize="medium">close</Icon>
        </IconButton>
      </div>
    ));
  }

  function viewArrayCookies() {
    return cookies.map((el, index) => (
      <div key={`cookies-el-${index.toString()}`} className="clientsViewArrayElement">
        {el.url} / {el.name} {el.wildcard ? "/ Con sottodomini" : ""}
        <IconButton
          onClick={() => {
            removeCookies(index);
          }}
          size="small"
          disableRipple
        >
          <Icon fontSize="medium">close</Icon>
        </IconButton>
      </div>
    ));
  }

  function viewArrayCookiesClient() {
    return cookiesClient.map((el, index) => (
      <div key={`cookies-client-el-${index.toString()}`} className="clientsViewArrayElement">
        {el.url} / {el.name} / {el.user}
        <IconButton
          onClick={() => {
            removeCookiesClient(index);
          }}
          size="small"
          disableRipple
        >
          <Icon fontSize="medium">close</Icon>
        </IconButton>
      </div>
    ));
  }

  function viewArrayPwUrls() {
    return pwUrls.map((el, index) => (
      <div key={`password-urls-el-${index.toString()}`} className="clientsViewArrayElement">
        {el.url} / {el.domain || "*"}
        <IconButton
          onClick={() => {
            removePwUrls(index);
          }}
          size="small"
          disableRipple
        >
          <Icon fontSize="medium">close</Icon>
        </IconButton>
      </div>
    ));
  }

  function viewArrayPwUrlsApp() {
    return pwUrlsApps.map((el, index) => (
      <div key={`password-urls-el-${index.toString()}`} className="clientsViewArrayElement">
        {el.domain} {el.wildcard ? "/ Con sottodomini" : ""}
        <IconButton
          onClick={() => {
            removePwUrlsApps(index);
          }}
          size="small"
          disableRipple
        >
          <Icon fontSize="medium">close</Icon>
        </IconButton>
      </div>
    ));
  }

  function viewArrayElementsWebhooks() {
    return webhooks.map((el, index) => (
      <div key={`webhooks-el-${index.toString()}`} className="clientsViewArrayElement">
        {el.url} - {el.auth} - {el.contentType}
        <IconButton
          onClick={() => {
            removeWebhook(index);
          }}
          size="small"
          disableRipple
        >
          <Icon fontSize="medium">close</Icon>
        </IconButton>
      </div>
    ));
  }
  const handleDeleteClient = async () => {
    try {
      await deleteClient(editElement.id);
      setDeleteClientPopup(false);
      setDeleteClientPopupSuccess(true);
      refreshClients();
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {popup ? (
        <SweetAlert
          style={{ display: "block" }}
          title={editElement.name}
          closeOnClickOutside={false}
          onConfirm={() => {
            if (name) {
              const clientToEdit = {
                name,
                vatId: vatId || "",
                status,
                service,
                serviceName,
                serviceDetail,
                email,
                ip,
                webhook: webhooks,
                ransomwares,
                contractExpiration: expiration ? moment(expiration) : null,
                contractStart: start ? moment(start) : null,
                lookupAfter: lookupAfter ? moment(lookupAfter) : null,
                passwords,
                cookies,
                usernames,
                cookiesClient,
                creditcards,
                passwordsUrl: pwUrls,
                passwordsApps: pwUrlsApps,
                combolists: combos,
                urlscan_search_ids: urlscanSearchIds,
              };

              if (hasChangedDomains) {
                clientToEdit.domainsToBeChecked = true;
              }

              editClient(clientToEdit, editElement.id)
                .then((res) => {
                  // eslint-disable-next-line
                  console.log("resssss", res);
                  const logToSend = {
                    email: user && user.email ? user.email : "",
                    type: `Edited client ${name}`,
                    date: new Date(),
                  };
                  addLog(logToSend);
                  setPopupSuccess(true);
                  refreshClients();
                })
                // eslint-disable-next-line
                .catch((err) => console.log("error", err));
              setPopup(false);
            }
          }}
          onCancel={() => {
            setPopup(false);
            setEditElement(null);
            setName("");
            setVatId("");
            setExpiration(null);
            setStart(null);
            setCookieurl("");
            setCombo("");
            setCreditcard("");
            setStatus("active");
            setServiceName("premium");
            setService([]);
            // setHasInsurance(false);
            setHasChangedDomains(false);
            setPasswordurl("");
            setPwUrlApp("");
            setCookiename("");
            setCookies([]);
            setCombos([]);
            setUrlscanSearchIds([]);
            setCreditcards([]);
            setCookiesClient([]);
            setPasswords([]);
            setPwUrlsApps([]);
            setEmail([]);
            setIp([]);
            setWebhooks([]);
            setRansomwares([]);
            setLookupAfter("");
          }}
          confirmBtnText={t("save")}
          cancelBtnText={t("close")}
          showCancel
          confirmBtnCssClass="popupCloseButton"
          confirmBtnStyle={{
            boxShadow: "none",
          }}
          cancelBtnCssClass="popupCloseButton"
          cancelBtnStyle={{
            boxShadow: "none",
          }}
        >
          {getInput(t("admin.name"), setName, name)}
          {getInput(t("admin.vatid_cf"), setVatId, vatId)}
          {getCalendar(expiration, setExpiration, t("admin.expiration"))}
          {getCalendar(start, setStart, t("admin.contract_start"))}
          <div>
            {getInputArrayEmail(t("admin.email"), setEmailInput, addEmail, emailInput, "email", "")}
            {email && email.length > 0 ? (
              <div className="clientsViewArrayContainer">
                {viewArrayElements(email, removeEmail, "email")}
              </div>
            ) : null}
            <hr className="add-customer-line" />
          </div>
          <div>
            {getInputArrayWebhook()}
            {webhooks && webhooks.length > 0 ? (
              <div className="clientsViewArrayContainer">{viewArrayElementsWebhooks()}</div>
            ) : null}
            <hr className="add-customer-line" />
          </div>
          {service.includes("stealer") ? (
            <div>
              {getInputArray(
                t("admin.passwords_domains"),
                setPasswordurl,
                addPasswords,
                passwordurl,
                "passwords",
                editElement.id,
                t("admin.passwords_domains_explanation"),
                passwordurlError,
                setPasswordurlError
              )}
              {passwords && passwords.length > 0 ? (
                <div className="clientsViewArrayContainer">
                  {viewArrayElements(passwords, removePasswords, "passwords")}
                </div>
              ) : null}
              {getInputArray(
                t("admin.usernames"),
                setUsername,
                addUsernames,
                username,
                "usernames",
                editElement.id,
                t("admin.usernames_explanation"),
                usernameError,
                setUsernameError
              )}
              {usernames && usernames.length > 0 ? (
                <div className="clientsViewArrayContainer">
                  {viewArrayElements(usernames, removeUsernames, "usernames")}
                </div>
              ) : null}
              {getInputPwUrl(editElement.id)}
              {pwUrls && pwUrls.length > 0 ? (
                <div className="clientsViewArrayContainer">{viewArrayPwUrls()}</div>
              ) : null}
              {getInputPwUrlApp()}
              {pwUrlsApps && pwUrlsApps.length > 0 ? (
                <div className="clientsViewArrayContainer">{viewArrayPwUrlsApp()}</div>
              ) : null}
              {getInputCookie(editElement.id)}
              {cookies && cookies.length > 0 ? (
                <div className="clientsViewArrayContainer">{viewArrayCookies()}</div>
              ) : null}
              {getInputCookieClient()}
              {cookiesClient && cookiesClient.length > 0 ? (
                <div className="clientsViewArrayContainer">{viewArrayCookiesClient()}</div>
              ) : null}
              {getInputArray(
                t("admin.ips"),
                setIpInput,
                addIp,
                ipInput,
                "ip",
                editElement.id,
                t("admin.ips_explanation"),
                errorIp,
                setErrorIp
              )}
              {ip && ip.length > 0 ? (
                <div className="clientsViewArrayContainer">
                  {viewArrayElements(ip, removeIp, "ips")}
                </div>
              ) : null}
            </div>
          ) : null}
          <div>
            {getInputArray(
              t("admin.combos"),
              setCombo,
              addCombos,
              combo,
              "combos",
              editElement.id,
              t("admin.combos_explanation"),
              comboError,
              setComboError
            )}
            {combos && combos.length > 0 ? (
              <div className="clientsViewArrayContainer">
                {viewArrayElements(combos, removeCombos, "combos")}
              </div>
            ) : null}
          </div>
          <div>
            {getInputArray(
              t("admin.urlscan_search_ids"),
              setUrlscanSearchId,
              addUrlscanSearchId,
              urlscanSearchId,
              "urlscan_search_ids",
              editElement.id,
              t("admin.urlscan_search_ids_explaination"),
              urlscanSearchIdError,
              setUrlscanSearchIdError
            )}
            {urlscanSearchIds && urlscanSearchIds.length > 0 ? (
              <div className="clientsViewArrayContainer">
                {viewArrayElements(urlscanSearchIds, removeUrlscanSearchId, "urlscan_search_ids")}
              </div>
            ) : null}
          </div>
          <div>
            <hr className="add-customer-line" />
            {getInputRansomwares()}
            {ransomwares && ransomwares.length > 0 ? (
              <div className="clientsViewArrayContainer">{viewArrayRansomwares()}</div>
            ) : null}
          </div>
          <div>
            <hr className="add-customer-line" />
            {getInputArray(
              t("admin.creditcards"),
              setCreditcard,
              addCreditcards,
              creditcard,
              "creditcards",
              editElement.id,
              t("admin.creditcards_explanation"),
              creditcardError,
              setCreditcardError,
              validateCC
            )}
            {creditcards && creditcards.length > 0 ? (
              <div className="clientsViewArrayContainer">
                {viewArrayElements(creditcards, removeCreditcards, "creditcards")}
              </div>
            ) : null}
          </div>
          {getCalendar(lookupAfter, setLookupAfter, t("admin.lookup_after"))}
          <div>
            <hr className="add-customer-line" />
            <div style={{ fontSize: "16px", textAlign: "left", marginBottom: "15px" }}>
              {t("admin.services_list")}
            </div>
            <AddServiceDetails
              availableServices={servicesAvailable}
              addElement={addServiceDetail}
            />
            <div style={{ padding: "50px 0" }}>{getServices()}</div>
            {getSelect(["active", "inactive"], setStatus, status)}
            {getSelect(
              ["premium", "combo", "stealer", "combo_potential"],
              editService,
              serviceName
            )}
          </div>
          {/* <div style={{ textAlign: "left" }}>
            <input
              type="checkbox"
              style={{ marginBottom: "30px" }}
              checked={hasInsurance}
              // value={hasInsurance}
              onChange={() => {
                setHasInsurance(!hasInsurance);
              }}
            />{" "}
            <span style={{ fontSize: "14px" }}>{t("admin.has_insurance_service")}</span>
          </div> */}
        </SweetAlert>
      ) : null}
      {popupSuccess ? (
        <SweetAlert
          style={{ display: "block" }}
          title={t("admin.customer_edited")}
          success
          onConfirm={() => {
            setPopupSuccess(false);
          }}
          confirmBtnText="Ok"
          confirmBtnCssClass="popupCloseButton"
          confirmBtnStyle={{
            boxShadow: "none",
          }}
        >
          {t("admin.customer_edited_text")}
        </SweetAlert>
      ) : null}
      {popupUrlAlready ? (
        <SweetAlert
          style={{ display: "block" }}
          title={t("admin.url_already_in_title")}
          error
          onConfirm={() => {
            setPopupUrlAlready(false);
          }}
          confirmBtnText="Ok"
          // confirmBtnCssClass={classes.button + " " + classes.success}
          confirmBtnCssClass="popupCloseButton"
          confirmBtnStyle={{
            boxShadow: "none",
          }}
        >
          {t("admin.url_already_in_text")}
        </SweetAlert>
      ) : null}
      {workerStarted ? (
        <SweetAlert
          style={{ display: "block" }}
          title={t("admin.worker_started_title")}
          success
          onConfirm={() => {
            setWorkerStarted(false);
          }}
          confirmBtnText="Ok"
          confirmBtnCssClass="popupCloseButton"
          confirmBtnStyle={{
            boxShadow: "none",
          }}
        >
          {t("admin.worker_started_text")}
        </SweetAlert>
      ) : null}
      {originalClients && originalClients.length > 0 ? (
        <div className="table_users_list">
          <div style={{ display: "flex", marginTop: "30px", justifyContent: "space-between" }}>
            <a href="/addclient" className="add-user-client">
              <PersonAddAlt1Icon fontSize="large" />
            </a>
            <div style={{ lineHeight: "40px", marginRight: "15px" }}>
              {t("displayed")}:
              <select
                onChange={(e) => {
                  setLimit(e.target.value);
                }}
                value={limit}
                className="selectDefault"
                style={{
                  padding: "0.75rem",
                  borderRadius: "0.375rem",
                  borderColor: "#d2d6da",
                  marginBottom: "30px",
                  marginLeft: "25px",
                }}
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </div>
          </div>
          <table style={{ border: "solid 1px #dedede" }}>
            <thead>
              <tr>
                <th
                  style={{
                    padding: "10px 0",
                    borderBottom: "solid 1px #dedede",
                    width: "25%",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    const filterClients = [].concat(clients);
                    if (orderBy === "name") {
                      setClients(filterClients.sort((a, b) => b.name.localeCompare(a.name)));
                      setOrderBy("nameReverse");
                    } else {
                      setClients(filterClients.sort((a, b) => a.name.localeCompare(b.name)));
                      setOrderBy("name");
                    }
                  }}
                >
                  {t("admin.name")}
                  {orderBy === "name" ? <Icon fontSize="medium">south</Icon> : null}
                  {orderBy === "nameReverse" ? <Icon fontSize="medium">north</Icon> : null}
                </th>
                <th
                  style={{
                    padding: "10px 0",
                    borderBottom: "solid 1px #dedede",
                    width: "25%",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    const filterClients = [].concat(clients);
                    if (orderBy === "vatId") {
                      setClients(filterClients.sort((a, b) => b.vatId.localeCompare(a.vatId)));
                      setOrderBy("vatIdReverse");
                    } else {
                      setClients(filterClients.sort((a, b) => a.vatId.localeCompare(b.vatId)));
                      setOrderBy("vatId");
                    }
                  }}
                >
                  {t("admin.vatid_cf")}
                  {orderBy === "vatId" ? <Icon fontSize="medium">south</Icon> : null}
                  {orderBy === "vatIdReverse" ? <Icon fontSize="medium">north</Icon> : null}
                </th>
                <th
                  style={{
                    padding: "10px 0",
                    borderBottom: "solid 1px #dedede",
                    width: "25%",
                    textAlign: "center",
                  }}
                >
                  {t("edit")}
                </th>
                <th
                  style={{
                    padding: "10px 0",
                    borderBottom: "solid 1px #dedede",
                    width: "25%",
                    textAlign: "center",
                  }}
                >
                  {t("delete")}
                </th>
              </tr>
            </thead>
            <tbody style={{ fontSize: "14px", textAlign: "center" }}>
              <tr>
                <th
                  style={{
                    padding: "10px 0",
                    borderBottom: "solid 1px #dedede",
                    width: "25%",
                    textAlign: "center",
                  }}
                >
                  <MDInput
                    type="input"
                    label={t("admin.name")}
                    fullWidth
                    onChange={(e) => {
                      // console.log(e.target.value);
                      const filterClients = [].concat(originalClients);
                      setClients(filterClients.filter((el) => el.name.includes(e.target.value)));
                    }}
                  />
                </th>
                <th
                  style={{
                    padding: "10px",
                    borderBottom: "solid 1px #dedede",
                    width: "25%",
                    textAlign: "center",
                  }}
                >
                  <MDInput
                    type="input"
                    label={t("admin.vatid_cf")}
                    fullWidth
                    onChange={(e) => {
                      // e.target.value;
                      const filterClients = [].concat(originalClients);
                      setClients(filterClients.filter((el) => el.vatId.includes(e.target.value)));
                    }}
                  />
                </th>
                <th
                  style={{
                    padding: "10px 0",
                    borderBottom: "solid 1px #dedede",
                    width: "25%",
                    textAlign: "center",
                  }}
                >
                  /
                </th>
              </tr>
              {clients.map((el, index) => (
                <tr style={{ borderBottom: "solid 1px #dedede" }} key={`user-${index.toString()}`}>
                  <td style={{ borderBottom: "solid 1px #dedede", padding: "10px 0" }}>
                    {el.name}
                    {el.status && el.status === "inactive" ? (
                      <IconButton size="small" disableRipple>
                        <Icon fontSize="medium" color="error">
                          close
                        </Icon>
                      </IconButton>
                    ) : null}
                  </td>
                  <td style={{ borderBottom: "solid 1px #dedede", padding: "10px 0" }}>
                    {el.vatId}
                  </td>
                  <td style={{ borderBottom: "solid 1px #dedede", padding: "10px 0" }}>
                    <IconButton
                      onClick={() => {
                        // console.log("el", el);
                        setPopup(true);
                        setEditElement(el);
                        setName(el.name);
                        setVatId(el.vatId);
                        setStatus(el.status);
                        setExpiration(el.contractExpiration || null);
                        setStart(el.contractStart || null);
                        setServiceName(el.serviceName);
                        setServiceDetail(el.serviceDetail);
                        setService(el.service);
                        // setHasInsurance(el.service?.length > 0 && el.service.includes("insurance"));
                        // switch (el.serviceName) {
                        //   case "premium":
                        //     setService(["stealer", "ransomware", "combo"]);
                        //     break;
                        //   case "stealer":
                        //     setService(["stealer", "ransomware"]);
                        //     break;
                        //   case "combo":
                        //     setService(["combo"]);
                        //     break;
                        //   case "combo_potential":
                        //     setService(["stealer", "ransomware", "combo"]);
                        //     break;
                        //   default:
                        //     setService(["stealer", "ransomware", "combo"]);
                        // }
                        setCookies(el.cookies);
                        setPwUrlsApps(el.passwordsApps);
                        setPasswords(el.passwords);
                        setPwUrls(el.passwordsUrl);
                        setCreditcards(el.creditcards || []);
                        setCookiesClient(el.cookiesClient);
                        setUsernames(el.usernames);
                        setCombos(el.combolists);
                        setUrlscanSearchIds(el.urlscan_search_ids || []);
                        setWebhooks(el.webhook);
                        setEmail(el.email);
                        setIp(el.ip);
                        setRansomwares(el.ransomwares || []);
                        setLookupAfter(el.lookupAfter);
                      }}
                      size="small"
                      disableRipple
                    >
                      <Icon fontSize="medium">edit</Icon>
                    </IconButton>
                  </td>
                  <td style={{ borderBottom: "solid 1px #dedede", padding: "10px 0" }}>
                    <IconButton
                      onClick={() => {
                        setDeleteClientPopup(true);
                        setEditElement(el);
                      }}
                    >
                      <Icon fontSize="medium">delete</Icon>
                    </IconButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {pages && pages > 1 ? <Pagination setPage={setPage} pages={pages} active={page} /> : null}
        </div>
      ) : null}
      {deleteClientPopup ? (
        <SweetAlert
          style={{ display: "block" }}
          title={t("admin.confirm_popup_delete_client_title")}
          onConfirm={handleDeleteClient}
          onCancel={() => {
            setDeleteClientPopup(false);
            setEditElement(null);
          }}
          confirmBtnText={t("confirm")}
          cancelBtnText={t("cancel")}
          showCancel
          confirmBtnCssClass="popupCloseButton"
          confirmBtnStyle={{
            boxShadow: "none",
          }}
          cancelBtnCssClass="popupCloseButton"
          cancelBtnStyle={{
            boxShadow: "none",
          }}
        >
          {t("admin.confirm_popup_delete_client_text")}
        </SweetAlert>
      ) : null}
      {deleteClientPopupSuccess ? (
        <SweetAlert
          style={{ display: "block" }}
          title={t("admin.success_popup_delete_client_title")}
          success
          onConfirm={() => {
            setDeleteClientPopupSuccess(false);
          }}
          confirmBtnText="Ok"
          confirmBtnCssClass="popupCloseButton"
          confirmBtnStyle={{
            boxShadow: "none",
          }}
        >
          {t("admin.success_popup_delete_client_text")}
        </SweetAlert>
      ) : null}
      <div style={{ marginTop: "50px" }}>
        <IconButton
          onClick={() => {
            refreshClientsDomains()
              .then((res) => {
                if (res && res.code === 200) {
                  setWorkerStarted(true);
                }
              })
              .catch((err) => {
                // eslint-disable-next-line
                console.log("error while starting worker for clients domains", err);
              });
          }}
          size="small"
          disableRipple
        >
          <Icon style={{ marginRight: "10px" }} fontSize="medium">
            refresh
          </Icon>{" "}
          {t("admin.start_script_domains")}
        </IconButton>
      </div>
    </DashboardLayout>
  );
}

export default ClientList;
