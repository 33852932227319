// prop-types is a library for typechecking of props
import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import addCredential from "api/auth/addCredential";
import controlUrlAvailable from "api/clients/controlUrlAvailable";
import { validateEmail } from "utilities/validateEmail";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import MDInput from "components/MDInput";
import SweetAlert from "react-bootstrap-sweetalert";
import Tooltip from "@mui/material/Tooltip";
import { useMaterialUIController } from "context";

function controlAddition(content, self) {
  return controlUrlAvailable(content, self)
    .then((res) => res)
    .catch((err) => {
      // eslint-disable-next-line
      console.log("error while checking availability", err);
    });
}

export default function AddCredential(props) {
  const [credential, setCredential] = useState("");
  const [errorInputEmail, setErrorInputEmail] = useState(false);
  const [emailUsed, setEmailUsed] = useState(false);

  const [controller, dispatch] = useMaterialUIController();
  const { user } = controller;
  const { t } = useTranslation();

  function tryAddCredential() {
    const credentialToAdd = {
      client: props.clientForCredential,
      credential,
    };
    addCredential(credentialToAdd)
      .then((res) => {
        if (res && res.message) {
          props.successFunction();
        } else {
          props.errorFunction();
        }
      })
      .catch((err) => {
        // eslint-disable-next-line
        console.log("error while adding credential", err);
        props.errorFunction();
      });
  }

  return (
    <div>
      <SweetAlert
        style={{ display: "block", position: "relative", textAlign: "left" }}
        title={t("user.add_credential")}
        onConfirm={() => {
          if (credential && validateEmail(credential)) {
            // TODO controllare che la mail non sia già stata presa, altrimenti errore
            controlAddition({
              type: "combos",
              content: credential,
            }).then((res) => {
              if (res) {
                tryAddCredential();
              } else {
                setEmailUsed(true);
              }
            });
          } else {
            setErrorInputEmail(true);
          }
        }}
        onCancel={() => {
          props.closeFunction();
        }}
        closeOnClickOutside
        showCancel
        cancelBtnText={t("cancel")}
        confirmBtnText={t("save")}
        confirmBtnCssClass="popupCloseButton"
        confirmBtnStyle={{
          boxShadow: "none",
        }}
        cancelBtnCssClass="popupCloseButton"
        cancelBtnStyle={{
          boxShadow: "none",
        }}
      >
        <div style={{ textAlign: "left" }}>
          <div style={{ textAlign: "left" }}>
            <div style={{ marginBottom: "20px", position: "relative" }}>
              <MDInput
                type={"input"}
                label={t("user.new_credential")}
                fullWidth
                value={credential}
                onChange={(e) => {
                  if (errorInputEmail) {
                    setErrorInputEmail(false);
                  }
                  if (emailUsed) {
                    setEmailUsed(false);
                  }
                  setCredential(e.target.value);
                }}
              />
              {errorInputEmail ? (
                <div style={{ color: "var(--red-color)", fontSize: "16px" }}>
                  {t("user.wrong_email")}
                </div>
              ) : null}
              {emailUsed ? (
                <div style={{ color: "var(--red-color)", fontSize: "16px" }}>
                  {t("user.email_already_used")}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="popupButtonEdit">
          <Tooltip title={t("close")} placement="top">
            <IconButton
              onClick={() => {
                props.closeFunction();
              }}
              size="small"
              disableRipple
            >
              <Icon fontSize="medium">close</Icon>
            </IconButton>
          </Tooltip>
        </div>
      </SweetAlert>
    </div>
  );
}
