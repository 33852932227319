const ransomware = {
  en: {
    translation: {
      add_hidden_results: "Add hidden results",
      ransomware_attacks: "Global ransomware",
      victim: "Victim",
      site: "Site",
      attack_group: "Attack group",
      sector: "Industry",
      nation: "Nation",
      date_from: "Start date",
      date_to: "End date",
      country: "Country",
      company_size: "Company size",
      record_updated: "Record updated",
      record_updated_text: "Record has been updated succesfully",
      no_ransomware_yet: "There are no ransomware yet",
      download_ransomware_csv: "Download csv file with ransomware data",
      disclamer:
        "The data and information reported might not be wholly reliable as it derives from what threat actors declared on their websites. ",
      victims_label1: "victims",
      victims_label2: "last year",
      calendar_label1: "victims",
      calendar_label2: "last 30 days",
      country_label1: "victims",
      country_label2: "in",
      country_label3: "last year",
      csv_date: "Date",
      download_csv_btn: "Download the csv file",
      custom_filters: "Custom filters",
      detection: "Detection",
      show_hidden: "Show hidden",
      page_description: "Strategic information on the current landscape of ransomware attacks",
    },
  },
  it: {
    translation: {
      add_hidden_results: "Aggiungi risultati nascosti",
      ransomware_attacks: "Ransomware globali",
      victim: "Vittima",
      site: "Sito",
      attack_group: "Gruppo attacco",
      sector: "Settore",
      nation: "Nazione",
      date_from: "Data inizio",
      date_to: "Data fine",
      country: "Paese",
      company_size: "Dimensione azienda",
      record_updated: "Riga aggiornata",
      record_updated_text: "La riga è stata aggiornata con successo",
      no_ransomware_yet: "Non ci sono ancora instanze di ransomware",
      download_ransomware_csv: "Scarica il file csv con i dati dei ransomware",
      disclamer:
        "I dati e le informazioni riportate potrebbero rivelarsi non completamente affidabili in quanto provenienti da quanto diffuso dai threat actor sui propri siti web.",
      victims_label1: "vittime",
      victims_label2: "nell'ultimo anno",
      calendar_label1: "vittime",
      calendar_label2: "negli ultimi 30 giorni",
      country_label1: "vittime",
      country_label2: "in",
      country_label3: "nell'ultimo anno",
      csv_date: "Data",
      download_csv_btn: "Scarica il file csv",
      custom_filters: "I tuoi filtri",
      detection: "Data rilevamento",
      show_hidden: "Mostra nascosti",
      page_description: "Informazioni strategiche sul panorama attuale degli attacchi ransomware",
    },
  },
};

export default ransomware;
